import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './InformationModal.styles';
import ModalWindow from '../ModalWindow';
import { setToLocalStorage } from '../../global/helpers';

interface InformationModalProps {
  onClose: () => void;
  setInformationModal: React.Dispatch<React.SetStateAction<boolean>>;
  informationData: [string] | null;
}

const OrderCancelModal = ({
  onClose,
  setInformationModal,
  informationData,
}: InformationModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow onClose={onClose} zIndex>
      <Styled.Wrapper>
        <Styled.ContactWrapper>
          <div className="title">{t('OrdersPage.UPDATE')}</div>
          <div className="text">
            {informationData?.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </div>
        </Styled.ContactWrapper>
        <button
          className="button"
          type="button"
          onClick={() => {
            setInformationModal(false);
            setToLocalStorage('looked-information', informationData);
          }}
        >
          {t('OrdersPage.SAW_IT')}
        </button>
      </Styled.Wrapper>
    </ModalWindow>
  );
};

export default OrderCancelModal;
