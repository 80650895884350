import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as Styled from './OrderProductsBlock.styles';

import {
  DeliveryType,
  OrderTableData,
  UserRole,
} from '../../../OrdersPage/types';
import ProductsTableRow from './components/ProductsTableRow';
import Button from '../../../../components/Button';
import {
  addToProducts,
  getCurrentOrderProducts,
  getFromLocalStorage,
  getCurrentOrderProductsPharmacist,
} from '../../../../global/helpers';
import addIcon from '../../../../global/media/add-icon.svg';
import AnalogModal from '../../../../components/AnalogModal';
import { newPriceProduct } from '../../../OrdersPage/helpers';
import { StatusType } from '../../../../global/types';
import VideoTestPage from '../../../../components/VideoTestPage';
import * as actions from '../../../OrdersPage/actions';
import * as actionsOrder from '../../actions';

interface OrderProductsBlockProps {
  data: OrderTableData;
  handleOpenAnalogModal: () => void;
  handleCloseAnalogModal: () => void;
  handleCLickSave: () => void;
  isAnalogModalOpen: boolean;
  editActive: boolean;
  editOperator: boolean;
  onEditOperator: () => void;
  setSubmitForm: React.Dispatch<React.SetStateAction<boolean>>;
  submitForm: boolean;
}

const OrderProductsBlock = ({
  data,
  handleOpenAnalogModal,
  handleCloseAnalogModal,
  isAnalogModalOpen,
  handleCLickSave,
  editActive,
  editOperator,
  onEditOperator,
  setSubmitForm,
  submitForm,
}: OrderProductsBlockProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userRole = getFromLocalStorage('role');

  const initialProducts =
    userRole === UserRole.PHARMACIST
      ? getCurrentOrderProductsPharmacist()
      : getCurrentOrderProducts();

  const [currentProducts, setCurrentProducts] = useState({});

  const updateProductFieldById = (id: string, field: string, value: any) => {
    setCurrentProducts((prevProducts: any) => {
      const newProducts = { ...prevProducts };
      if (newProducts[id]) {
        const keys = field.split('.');
        const lastKey = keys.pop();
        const targetObject = keys.reduce((obj, key) => {
          if (!obj[key]) obj[key] = {};
          return obj[key];
        }, newProducts[id]);

        if (lastKey) {
          targetObject[lastKey] = value;
        }
      }
      return newProducts;
    });
  };

  const education = history.location.pathname.includes('/test-orders');
  const id = history.location.pathname.split('/')[2];

  const [addProductOpen, setAddProductOpen] = useState<boolean>(false);
  const [newPrice, setNewPrice] = useState<string>('');

  const cancelPromocode = (id: string) => {
    dispatch(
      actions.cancelPromocode.request({
        id,
      })
    );
  };

  const handleSetNewPrice = (price: number) => {
    setNewPrice(`${price} ₸`);
  };

  useEffect(() => {
    if (Object.keys(currentProducts).length === 0)
      setCurrentProducts(initialProducts);
  }, [initialProducts]);

  useEffect(() => {
    if (submitForm && userRole === UserRole.PHARMACIST) {
      const processedProducts = Object.values(currentProducts).map(
        (el: any) => {
          const item = { ...el.item };

          if (item.other_price && typeof item.other_price.price === 'string') {
            item.other_price.price = parseFloat(item.other_price.price);
          }
          if (item.term && typeof item.term.offer_price === 'string') {
            item.term.offer_price = parseFloat(item.term.offer_price);
          }

          return item;
        }
      );

      dispatch(
        actionsOrder.sendEdit.request({
          id: data?.id,
          data: processedProducts,
          postEffect: () => {},
        })
      );

      setSubmitForm(false);
    }
  }, [submitForm]);

  useEffect(() => {
    addToProducts(data.products);
  }, [id, data.products]);

  useEffect(() => {
    setNewPrice(newPriceProduct(Object.values(currentProducts)));
  }, [currentProducts]);

  useEffect(() => {
    setNewPrice(newPriceProduct(Object.values(currentProducts)));
  });

  const TableHeaderOperator = (
    <thead>
      <Styled.ProductsTableHeaderRow>
        <th
          className={`table__header-cell ${
            editOperator ? 'item-number-operator' : 'item-number-operator-edit'
          }`}
        >
          {t('OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.NUM')}
        </th>
        <th
          className={`table__header-cell  ${
            editOperator
              ? 'product-info-operator'
              : 'product-info-operator-edit'
          } `}
        >
          <div className="grid-block">
            <div className="product-info-daribar">
              {' '}
              {t(
                'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.PRODUCT_INFO_DARIBAR'
              )}
            </div>
            <div className="product-info-source">
              {' '}
              {t(
                'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.PRODUCT_INFO_SOURCE'
              )}
            </div>
          </div>
        </th>

        <th
          className={`table__header-cell ${
            editOperator ? 'quantity-operator' : 'quantity-operator-edit'
          } `}
        >
          {t(
            'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.QUANTITY'
          )}
        </th>
        <th
          className={`table__header-cell ${
            editOperator ? 'amount-operator' : 'amount-operator-edit'
          }`}
        >
          {editOperator
            ? t(
                'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT'
              )
            : t(
                'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT_OLD'
              )}
        </th>

        <th
          className={`table__header-cell ${
            editOperator
              ? 'availability-operator'
              : 'availability-operator-edit'
          }`}
        >
          {t(
            'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.AVAILABILITY'
          )}
        </th>
        {/* <th
          className={`table__header-cell ${
            editOperator ? 'amount-operator' : 'amount-operator-edit'
          }`}
        >
          {t(
            'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.AMOUNT_CITY'
          )}
        </th> */}
        {!editOperator && (
          <th
            className={`table__header-cell ${
              editOperator ? 'amount-operator' : 'amount-operator-edit'
            }`}
          >
            {editOperator
              ? t(
                  'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT'
                )
              : t(
                  'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT_NEW'
                )}
          </th>
        )}
      </Styled.ProductsTableHeaderRow>
    </thead>
  );

  const TableHeaderPharmacy = (
    <thead>
      <Styled.ProductsTableHeaderRow>
        <th
          className={`table__header-cell ${
            editActive ? 'item-number-pharmacy' : 'item-number-pharmacy-edit'
          }`}
        >
          {t('OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.NUM')}
        </th>
        <th
          className={`table__header-cell  ${
            editActive ? 'product-info-pharmacy' : 'product-info-pharmacy-edit'
          } `}
        >
          <div className="header-name">
            {t(
              'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.PRODUCT_INFO_DARIBAR'
            )}
          </div>
        </th>
        {!isMobile && !editActive && (
          <>
            <th
              className={`table__header-cell ${
                editActive ? 'quantity-pharmacy' : 'quantity-pharmacy-edit'
              } `}
            >
              {t(
                'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.QUANTITY'
              )}
            </th>
            <th
              className={`table__header-cell ${
                editActive ? 'amount-pharmacy' : 'amount-pharmacy-edit'
              }`}
            >
              {editActive
                ? t(
                    'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT'
                  )
                : t(
                    'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT_OLD'
                  )}
            </th>
          </>
        )}
        {(data.status === StatusType.ON_CLARIFICATION || !editActive) && (
          <th
            className={`table__header-cell ${
              editActive
                ? 'availability-pharmacy'
                : 'availability-pharmacy-edit'
            }`}
          >
            {t(
              'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.AVAILABILITY'
            )}
          </th>
        )}
        {!editActive && !isMobile && (
          <th
            className={`table__header-cell ${
              editActive ? 'amount-pharmacy' : 'amount-pharmacy-edit'
            }`}
          >
            {editActive
              ? t(
                  'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT'
                )
              : t(
                  'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.TOTAL_AMOUNT_NEW'
                )}
          </th>
        )}
        {!editActive &&
          userRole !== UserRole.OPERATOR &&
          userRole !== UserRole.HEAD_OPERATOR &&
          !isMobile && (
            <th
              className={`table__header-cell ${
                editActive ? 'analog-pharmacy' : 'analog-pharmacy-edit'
              }`}
            >
              {t(
                'OrdersPage.OrderDetailedModal.ProductsTable.headerTitles.ANALOG'
              )}
            </th>
          )}
      </Styled.ProductsTableHeaderRow>
    </thead>
  );

  return (
    <>
      <Styled.OrderProductContainer id="products">
        <div className="title">
          <div className="title-block-main">
            <div className="title-block">
              {(userRole === UserRole.OPERATOR ||
                userRole === UserRole.HEAD_OPERATOR ||
                userRole === UserRole.ADMIN) &&
              (data.soft === 'STANDART' || data.soft === 'INFO_APTEKA') &&
              data.status !== StatusType.NEW ? (
                <div className="main-title">
                  {t('OrdersPage.OrderDetailedModal.PRODUCTS_TABLE_TITLE')}
                </div>
              ) : (
                <>
                  <div className="main-title">
                    {t('OrdersPage.OrderDetailedModal.PRODUCTS_TABLE_TITLE')}
                  </div>
                  {(userRole === UserRole.OPERATOR ||
                    userRole === UserRole.HEAD_OPERATOR ||
                    userRole === UserRole.ADMIN) &&
                    data.statusPharmacy !== StatusType.IN_PHARMACY_READY &&
                    data.statusPharmacy !== StatusType.CANCELED &&
                    data.statusPharmacy !== StatusType.COMPLETED && (
                      <div
                        className={`edit ${!editOperator && 'edit-active'}`}
                        onClick={onEditOperator}
                      >
                        {data.status !== StatusType.CANCELED &&
                          data.status !== StatusType.COMPLETED &&
                          t('OrdersPage.OrderDetailedModal.EDIT_TITLE')}
                      </div>
                    )}
                </>
              )}
            </div>
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) &&
              !editOperator && (
                <div
                  className="add-product"
                  onClick={() => setAddProductOpen(true)}
                >
                  <div>{t('OrdersPage.OrderDetailedModal.ADD_PRODUCT')}</div>
                  <img src={addIcon} alt="add product" />
                </div>
              )}
          </div>
        </div>
        <Styled.AttentionTitle>
          {t('OrderPage.ATTENTION')}
        </Styled.AttentionTitle>
        <Styled.ProductsTableContainer>
          {userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR
            ? TableHeaderOperator
            : TableHeaderPharmacy}
          <tbody>
            <tbody>
              {currentProducts &&
              Object.values(currentProducts) &&
              !editActive &&
              !editOperator
                ? Object.values(currentProducts)?.map(
                    (product: any, index: any) => (
                      <ProductsTableRow
                        key={product.sku}
                        sku={product.sku}
                        data={product}
                        source={data.source}
                        orderId={data.orderNumber}
                        itemIndex={index + 1}
                        handleCloseAnalogModal={handleCloseAnalogModal}
                        isAnalogModalOpen={isAnalogModalOpen}
                        handleOpenAnalogModal={handleOpenAnalogModal}
                        editActive={editActive}
                        editOperator={editOperator}
                        onCountChange={handleSetNewPrice}
                        updateProductFieldById={updateProductFieldById}
                      />
                    )
                  )
                : data.products?.map((product: any, index: any) => (
                    <ProductsTableRow
                      key={product.sku}
                      sku={product.sku}
                      data={product}
                      source={data.source}
                      orderId={data.orderNumber}
                      itemIndex={index + 1}
                      handleCloseAnalogModal={handleCloseAnalogModal}
                      isAnalogModalOpen={isAnalogModalOpen}
                      handleOpenAnalogModal={handleOpenAnalogModal}
                      editActive={editActive}
                      editOperator={editOperator}
                      onCountChange={handleSetNewPrice}
                    />
                  ))}
            </tbody>
          </tbody>
        </Styled.ProductsTableContainer>
        <Styled.SummaryContainer>
          {(editOperator &&
            data.status !== StatusType.ON_CLARIFICATION &&
            (userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR)) ||
          (editOperator &&
            data.statusPharmacy !== StatusType.ON_CLARIFICATION &&
            userRole !== UserRole.OPERATOR &&
            userRole !== UserRole.HEAD_OPERATOR) ? (
            <div>
              <div>
                <div>
                  {t(
                    'OrdersPage.OrderDetailedModal.ProductsTable.TOTAL_AMOUNT_TITLE'
                  )}
                </div>{' '}
                <div className="wrapper-promo-code">
                  <div
                    className={
                      (userRole === UserRole.OPERATOR ||
                        userRole === UserRole.HEAD_OPERATOR ||
                        userRole === UserRole.ADMIN) &&
                      data.promoCode &&
                      data.promoCode.discount_target !== 'delivery'
                        ? 'table-cell__amount-discount'
                        : ''
                    }
                  >
                    {data.amount}{' '}
                    {data.products[0].sku.includes('discount') && (
                      <span className="sale-amount-info">
                        {t('OrdersPage.PRICE_WITH_SALE')}
                      </span>
                    )}
                  </div>
                  {(userRole === UserRole.OPERATOR ||
                    userRole === UserRole.HEAD_OPERATOR ||
                    userRole === UserRole.ADMIN) &&
                    data.promoCode &&
                    data.promoCode.discount_target !== 'delivery' && (
                      <>
                        <div>
                          - {data.promoCode.value}{' '}
                          {data.promoCode?.discount_type === 'money'
                            ? '₸'
                            : '%'}
                        </div>

                        <div className="table-cell__amount-accent">
                          {data.amountWithDiscount}
                        </div>
                        <div className="promo-code">Промокод:</div>
                        <div className="table-cell__amount-accent">
                          {data.promoCode?.code}
                        </div>
                        <div className="promo-code">Описание промокода:</div>
                        <div className="table-cell__amount-accent">
                          {data.promoCode?.description}
                        </div>
                        {userRole === UserRole.ADMIN && (
                          <div
                            onClick={() => {
                              data.promoCode?.max_user_orders !== 1 &&
                                cancelPromocode(data.id);
                            }}
                            className={`cancel_button ${
                              data.promoCode?.max_user_orders === 1 &&
                              'disabled'
                            } `}
                          >
                            Oтменить применение
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`${
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR
                  ? 'summary-edit-operator'
                  : 'summary-edit-pharmacy'
              } ${
                (userRole === UserRole.OPERATOR ||
                  userRole === UserRole.HEAD_OPERATOR ||
                  userRole === UserRole.ADMIN) &&
                data.status === StatusType.ON_CLARIFICATION &&
                editOperator &&
                'summary-edit-operator-pad'
              }`}
            >
              <div className="old-price">
                <div>
                  {t(
                    'OrdersPage.OrderDetailedModal.ProductsTable.TOTAL_AMOUNT_TITLE_OLD'
                  )}
                </div>{' '}
                <span>{data.oldPrice}</span>
              </div>
              <div className="new-price">
                <div>
                  {t(
                    'OrdersPage.OrderDetailedModal.ProductsTable.TOTAL_AMOUNT_TITLE_NEW'
                  )}
                </div>{' '}
                <span>{Number(newPrice).toFixed(2)} ₸</span>
              </div>
            </div>
          )}
          {!editActive &&
            userRole !== UserRole.OPERATOR &&
            userRole !== UserRole.HEAD_OPERATOR &&
            userRole !== UserRole.ADMIN && (
              <div
                className={`btn ${
                  (userRole === UserRole.OPERATOR ||
                    userRole === UserRole.HEAD_OPERATOR ||
                    userRole === UserRole.ADMIN) &&
                  'btn-operator'
                }`}
              >
                <Button
                  text={t('OrdersPage.OrderDetailedModal.SAVE_TITLE')}
                  onClick={handleCLickSave}
                />
                <div className="hint-block">
                  <div className="first-hint">
                    {t('OrderPage.Hints.SAVE_HINT_FIRST')}
                  </div>
                  <br />
                  <div className="second-hint">
                    {t('OrderPage.Hints.SAVE_HINT_SECOND')}
                  </div>
                </div>
              </div>
            )}
          {!editOperator &&
            (userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <div
                className={`btn ${
                  (userRole === UserRole.OPERATOR ||
                    userRole === UserRole.HEAD_OPERATOR ||
                    userRole === UserRole.ADMIN) &&
                  'btn-operator'
                }`}
              >
                <Button
                  text={t('OrdersPage.OrderDetailedModal.SAVE_TITLE2')}
                  onClick={handleCLickSave}
                />
              </div>
            )}
        </Styled.SummaryContainer>
        {education &&
          data.deliveryType === DeliveryType.DELIVERY &&
          data.status === StatusType.IN_PHARMACY_READY && (
            <VideoTestPage url="https://www.youtube.com/watch?v=-bNNYSwWZz8&ab_channel=UlanKyzyrov%28Daribar%29" />
          )}
      </Styled.OrderProductContainer>
      {addProductOpen && (
        <AnalogModal
          newProduct
          onClose={() => setAddProductOpen(false)}
          source={data.source}
          orderId={data.orderNumber}
        />
      )}
    </>
  );
};

export default OrderProductsBlock;
