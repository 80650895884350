import * as Yup from 'yup';
import { SalesProductFormatedData } from './types';

export const formatSalesProductData = (product: SalesProductFormatedData) => {
  return {
    pharmacy_product: product.pharmacy_product,
    expiration_date: product.expiration_date,
    quantity: product.quantity,
    current_price: product.current_price,
    discount_price: product.discount_price,
    sku: product.sku,
    series: product.series,
    comment: product.comment,
  };
};

const salesProductSchemaCommon = {
  current_price: Yup.string().required(
    'Текущая цена в аптеке не должна быть пустой'
  ),
  discount_price: Yup.string()
    .required('Цена товара со скидкой не должна быть пустой')
    .matches(/^[^0].*/, 'Цена не должна начинаться с "0"'),
  expiration_date: Yup.string().required('Срок годности не должен быть пустым'),
  series: Yup.string().required('Серия не должна быть пустой'),
  quantity: Yup.string().required('Поле не должно быть пустым'),
  sku: Yup.string().required('Поле не должно быть пустым'),
  pharmacy_product: Yup.string().required('Поле не должно быть пустым'),
};
export const salesProductsSchemaCommon = Yup.object(salesProductSchemaCommon);
export const salesProductsSchema = Yup.array().of(
  Yup.object().shape(salesProductSchemaCommon)
);

export function isDateFresh(dateString: string) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const timeDifference = inputDate.getTime() - currentDate.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return daysDifference > 123;
}

export const formatSalesProductsData = (
  productsList: SalesProductFormatedData[]
) => {
  return productsList.map((product) => formatSalesProductData(product));
};

export const getProductsBySearchValue = (
  productsList: SalesProductFormatedData[],
  searchProductValue: string
): SalesProductFormatedData[] => {
  return productsList.filter((product) =>
    product.sku.toLowerCase().includes(searchProductValue.toLowerCase().trim())
  );
};
